<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="success"
          variant="outline"
          @click="$router.push('/clients/new-client')"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-02"></i>
          </span>
          <span class="btn-inner--text"> New Client</span>
        </soft-button>
      </div>
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
          <soft-button
            id="navbarDropdownMenuLink2"
            color="dark"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</soft-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Paid</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Refunded</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Canceled</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchForClient"
                placeholder="Search Ref#, Name, Email, Phone"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table id="client-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>Company</th>
                    <th>First</th>
                    <th>Last</th>
                    <th>Reference #</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in queriedData"
                    :key="client.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewClientDetails(client)"
                  >
                    <td>
                      <soft-avatar
                        v-if="client.metadata"
                        :img="client.metadata.url"
                        size="s"
                        class="me-0"
                        alt="user image"
                      />
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ client.company }}
                        </p>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{ client.firstName }}
                        </span>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>{{ client.lastName }}</span>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ client.refNumber }}
                        </p>
                      </div>
                    </td>

                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{ client.email }}
                        </span>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span class="my-2 text-xs">
                          {{ client.phone }}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(client.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <!-- <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{ client.storeId }}</span>
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    @close="(showModal = false), (selectedClient = {})"
    :showSaveBtn="false"
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
  >
    <template v-slot:title>
      <h2>Client Details</h2>
    </template>
    <template v-slot:body>
      <div class="client-details-wrapper">
        <ClientDetails
          @update-info="updateInfo"
          @save-changes="saveChanges"
          :client="selectedClient"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import ClientDetails from "./ClientDetails.vue";
import {
  getDocs,
  setDoc,
  collection,
  doc,
  db,
  orderBy,
  query,
  onSnapshot,
  where,
} from "@/config/firebaseInit.js";
import { mapState } from "vuex";

export default {
  name: "client-list",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
    ClientDetails,
  },
  data() {
    return {
      tableData: [],
      clients: [],
      showModal: false,
      selectedClient: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        client: "",
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),

    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    if (this.isEmployee || this.isSuperAdmin || this.isSalesRep) {
      this.getAllClients();
    } else {
      this.$router.push("/orders");
    }
  },
  methods: {
    async getAllClients() {
      let clientQuery;
      const clientRef = collection(db, "clients");
      if (this.isSalesRep) {
        clientQuery = query(
          clientRef,
          where("salesRep.id", "==", this.currentUser.id)
        );
      } else {
        clientQuery = query(clientRef, orderBy("createdAt", "desc"));
      }
      const unsub = await onSnapshot(clientQuery, (querySnapshot) => {
        this.clients = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        this.tableData = this.clients;
      });
    },

    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewClientDetails(client) {
      this.selectedClient = client;
      setTimeout(() => {
        this.showModal = true;
      }, 1000);
    },
    async saveChanges() {
      let selectedClient = JSON.parse(JSON.stringify(this.selectedClient));
      const id = selectedClient.id;
      delete selectedClient.id;

      await setDoc(doc(db, "clients", id), selectedClient)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Client ${selectedClient.firstName} has been updated`,
            type: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateInfo(data, key) {
      if (key == "info") {
        this.selectedClient = Object.assign(this.selectedClient, data);
      } else if (key == "address") {
        this.selectedClient.address = Object.assign(
          this.selectedClient.address,
          data
        );
      }
    },
    searchForClient(e) {
      if (!e.target.value) {
        this.tableData = this.clients;
      }
      let input = e.target.value.toLowerCase();
      const validateClient = (client) => {
        if (
          client.refNumber.includes(input) ||
          client.email.toLowerCase().includes(input) ||
          client.lastName.toLowerCase().includes(input) ||
          client.firstName.toLowerCase().includes(input)
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.clients.filter((client) => validateClient(client));
    },
  },
};
</script>
<style scoped>
.table-row {
  cursor: pointer;
}

.search-bar {
  position: relative;
  width: 200px;
  top: 10px;
  left: 10px;
}

.pagination-wrapper {
  padding: 20px;
}

.align-items-center {
  padding: 15px !important;
}
</style>
