<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About User</h5>
    <p class="mb-0 text-sm">Mandatory Information</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.firstName"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Last Name</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Phone #</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="phone"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Email Address</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.email"
                placeholder="eg: user@email.com"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Password</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.current"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Repeat Password</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.repeat"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <!-- <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto mb-0 js-btn-next"
              @click="this.$parent.nextStep"
              >Next</soft-button
            > -->
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="saveUser"
          >Save</soft-button
        >
      </div>
    </div>
  </div>
</template>
    
    <script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

//Firebase
import {
  collection,
  doc,
  db,
  setDoc,
  getDocs,
  functions,
  httpsCallable,
} from "@/config/firebaseInit.js";

export default {
  name: "store-user-info",
  components: {
    SoftInput,
    SoftButton,
  },
  data() {
    return {
      addUser: httpsCallable(functions, "addUser"),
      selectedStore: "",
      inputPasswordType: "password",
      stores: [],
      errors: [],
      roles: {
        super: "DIS Executive",
        client: "Client Executive",
        store: "Store Employee",
        employee: "DIS Employee",
        factory: "Factory Employee",
      },
      password: {
        current: "",
        repeat: "",
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        client: {},
        storeId: "",
        role: "",
        admin_access: {
          client: false,
          store: true,
        },
        createdAt: Date.now(),
      },
    };
  },
  methods: {
    async saveUser() {
      this.user.client = this.$store.state.authModule.store.client;
      this.user.storeId = this.$store.state.authModule.store.id;
      this.user.role = "Store Rep";

      this.addUser({
        email: this.user.email,
        password: this.password.current,
        displayName: `${this.user.firstName} ${this.user.lastName}`,
      }).then(async (res) => {
        this.user.id = res.data;
        await setDoc(doc(db, "users", res.data), this.user).then(() => {
          this.$swal({
            icon: "success",
            title: "User Successfully Added",
            text: `User ${this.user.firstName} has been added to the roster`,
            type: "success-message",
          }).then(() => this.$router.push("/users"));
        });
      });
    },
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
  },
};
</script>
    