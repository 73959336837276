<template>
  <div v-if="user.id" class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div class="card-body p-3 pt-0">
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div
                  v-if="$store.state.authModule.isSuperAdmin"
                  class="col-12 col-sm-6"
                >
                  <label>Company/Client</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-if="!user.client"
                        id="company"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        disabled
                        value="Luna Collection"
                        placeholder="Company"
                        required
                      />

                      <input
                        v-else
                        id="company"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        disabled
                        v-model="user.client.name"
                        placeholder="Company"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="$store.state.authModule.isSuperAdmin"
                  class="col-12 col-sm-6"
                >
                  <label>Role</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="company"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        disabled
                        :value="user.role"
                        placeholder="Company"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>First Name</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="firstName"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        :disabled="!editInit"
                        v-model="user.firstName"
                        placeholder="First Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>Last Name</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="lastName"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        :disabled="!editInit"
                        v-model="user.lastName"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>Email Address</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="email"
                        type="text"
                        class="form-control multisteps-form__input"
                        name="text"
                        :disabled="!editInit"
                        v-model="user.email"
                        placeholder="eg: user@email.com"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <label>Phone #</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="phone"
                        type="tel"
                        class="form-control multisteps-form__input"
                        name="text"
                        :disabled="!editInit"
                        v-model="user.phone"
                        placeholder="eg: +1 (347) 123-1234"
                        required
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <label>Administration Access</label>
                  <div class="form-group">
                    <select
                      id="choices-category-edit"
                      v-model="selectedAdminAccess"
                      class="form-control"
                      name="choices-category"
                      disabled
                      @change="handleAdminAccess"
                    >
                      <option value="super">Super Admin</option>
                      <option value="user">User Admin</option>
                      <option value="employee">DIS Admin</option>
                      <option value="store">Store Admin</option>
                      <option value="factory">Factory Admin</option>
                    </select>
                  </div>
                </div> -->
              </div>
              <!-- <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>Password</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="password"
                        :type="inputPasswordType"
                        class="form-control multisteps-form__input"
                        name="password"
                        v-model="password.current"
                        placeholder="******"
                        isRequired
                      />
                      <div
                        @click="handlePasswordType"
                        class="input-group-text click-event"
                      >
                        <span class="">
                          <i class="fas fa-eye"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>Repeat Password</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        id="password"
                        :type="inputPasswordType"
                        class="form-control multisteps-form__input"
                        name="password"
                        v-model="password.repeat"
                        placeholder="******"
                        isRequired
                      />
                      <div
                        @click="handlePasswordType"
                        class="input-group-text click-event"
                      >
                        <span class="">
                          <i class="fas fa-eye"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <h5 class="mt-5">Password requirements</h5>
                  <p class="text-muted mb-2">
                    Please follow this guide for a strong password:
                  </p>
                  <ul class="text-muted ps-4 mb-0 float-start">
                    <li>
                      <span class="text-sm">One special characters</span>
                    </li>
                    <li>
                      <span class="text-sm">Min 6 characters</span>
                    </li>
                    <li>
                      <span class="text-sm"
                        >One number (2 are recommended)</span
                      >
                    </li>
                    <li>
                      <span class="text-sm">Change it often</span>
                    </li>
                  </ul>
                </div>
              </div> -->

              <div class="button-row">
                <soft-button
                  type="button"
                  color="dark"
                  variant="gradient"
                  class="ms-auto mb-0 js-btn-next"
                  @click-btn="editInit = !editInit"
                  >{{ editInit ? "Cancel" : "Edit" }}</soft-button
                >
                <soft-button
                  v-if="editInit"
                  type="button"
                  color="primary"
                  variant="gradient"
                  class="ms-auto mb-0 js-btn-next"
                  @click-btn="saveChanges"
                  >Save changes</soft-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="delete" class="card mt-4">
      <div class="card-header">
        <h5>Delete Account</h5>
        <p class="text-sm mb-0">
          Once you delete this account, there is no going back. Please be
          certain.
        </p>
      </div>
    </div> -->
  </div>
</template>
    
    <script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";

export default {
  name: "StoreDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
  },
  props: {
    user: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedAdminAccess: "",
      roles: {
        super: "DIS Executive",
        client: "Client Executive",
        store: "Store Employee",
        employee: "DIS Employee",
        factory: "Factory Employee",
      },
      editInit: false,
      password: {
        current: null,
        repeat: null,
      },
    };
  },
  watch: {
    user() {
      const roles = {
        "DIS Executive": "super",
        "Client Executive": "client",
        "Store Employee": "store",
        "DIS Employee": "employee",
        "Factory Employee": "factory",
      };
      this.selectedAdminAccess = roles[this.user.role];
    },
  },
  methods: {
    handleAdminAccess(e) {
      const access = e.target.value;
      this.user.admin_access = {
        super: false,
        client: false,
        store: false,
        employee: false,
        factory: false,
      };
      this.user.admin_access[access] = true;
      this.user.role = this.roles[access];
    },
  },
};
</script>
    <style scoped>
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}
</style>
    