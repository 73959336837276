<template>
  <div class="messenger-wrapper">
    <soft-badge class="badge" color="success">{{ messageThreads.length }}</soft-badge>
    <div
      @click="invokeMessageFunc()"
      data-bs-toggle="modal"
      data-bs-target="#messenger"
      class="icon-btn"
    >
      <MessageIcon />
    </div>
    <div
      class="modal fade"
      id="messenger"
      tabindex="-1"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      ref="Modal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="ModalLabel">Messenger</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('is-messenger-open', false)"
            ></button>
          </div>
          <div class="modal-body">
            <div class="messenger-app">
              <div class="contact-list">
                <div class="search-input">
                  <input
                    @input="searchForUser($event)"
                    type="text"
                    v-model="searchValue"
                    placeholder="Search For Contact"
                    id=""
                  />
                  <div v-if="searchValue" class="search-query">
                    <div
                      class="results"
                      v-for="contact in searchQuery"
                      :key="contact.id"
                      @click="openNewChat(contact)"
                    >
                      {{ `${contact.firstName} ${contact.lastName}` }}
                    </div>
                  </div>
                </div>
                <div
                  v-for="(contact, index) in messageThreads"
                  :key="index"
                  @click="selectContact(contact)"
                  class="contact"
                  :class="{ selected: contact.id == selectedThread.id }"
                >
                  {{ contact.name }}
                </div>
              </div>
              <div class="messenger">
                <div ref="messageList" class="message-list">
                  <div
                    v-for="(message, index) in selectedThread.messages"
                    :key="index"
                    :class="[
                      'message',
                      message.sender === admin.id ? 'outgoing' : 'incoming',
                    ]"
                  >
                    <div v-if="message.imageIncluded" id="image-container-msg">
                      <img
                        :src="message.image_meta_data.url"
                        :alt="message.image_meta_data.name"
                        srcset=""
                      />
                    </div>
                    {{ message.text }}
                  </div>
                </div>
                <div class="message-input">
                  <textarea
                    ref="inputBox"
                    v-model="newMessage"
                    @input="adjustInputHeight"
                    @keydown.enter="sendMessage('text')"
                    placeholder="Type a message..."
                    :style="{ height: inputBoxHeight }"
                  ></textarea>
                  <button class="send-btn" @click="sendMessage('text')">
                    Send
                  </button>
                  <i
                    @click="$refs.upload.click()"
                    class="fas fa-paperclip upload-icon"
                  ></i>
                </div>
                <div v-if="uploadedFile" class="image-modal">
                  <button
                    type="button"
                    class="btn-close img-btn-close"
                    @click="closeImageModal"
                  ></button>
                  <div class="image-container">
                    <img
                      class="uploaded-image"
                      :src="uploadFileBlob"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="caption-input">
                    <input
                      type="text"
                      v-model="captionValue"
                      placeholder="Caption (Optional)"
                      id=""
                    />
                  </div>
                  <button @click="sendMessage('image')" class="send-img-btn">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      ref="upload"
      type="file"
      accept="image/*"
      class="hide_upload"
      @change="uploadMedia"
    />
  </div>
</template>

<script>
import {
  collection,
  doc,
  db,
  getDoc,
  updateDoc,
  setDoc,
  onSnapshot,
  arrayUnion,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@/config/firebaseInit.js";

import Modal from "./Modal.vue";
import MessageIcon from "./Icon/MessageIcon.vue";
import SoftBadge from "./SoftBadge.vue";

export default {
  components: {
    Modal,
    MessageIcon,
    SoftBadge,
  },
  emits: ["is-messenger-open"],
  data() {
    return {
      users: [],
      admin: null,
      captionValue: "",
      searchValue: null,
      searchQuery: [],
      inputBoxHeight: "auto",
      startMessageApp: false,
      messageThreads: [],
      selectedThread: {},
      newMessage: "",
      uploadedFile: null,
      uploadFileBlob: null,
    };
  },
  methods: {
    invokeMessageFunc() {
      this.admin = this.$store.state.authModule.admin;
      this.getAdminUsers();
      this.getUserThreads();
      this.$emit("is-messenger-open", true);
    },
    closeImageModal() {
      this.uploadedFile = null;
    },

    uploadMedia(e) {
      this.load = true;
      this.uploadedFile = e.target.files[0];
      this.uploadFileBlob = URL.createObjectURL(this.uploadedFile);
    },
    async saveMediaToStorageAndRetrieveURL(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const refStr = `messages/${this.selectedThread.id}/${file.name}`;
        const fileRef = ref(storage, refStr);

        try {
          await uploadBytes(fileRef, file);
          let downloadURL = await getDownloadURL(ref(storage, fileRef));
          const meta = {
            url: downloadURL,
            name: file.name,
            type: file.type.split("/")[1],
            ref: refStr,
            uploadedAt: Date.now(),
          };
          return meta;
        } catch (error) {
          console.error(err);
          alert("could not upload image, please try again.");
        }
      };

      return await reader.onload();
    },
    getUserThreads() {
      const messagesRef = collection(db, "users", this.admin.id, "messages");
      const unsub = onSnapshot(
        messagesRef,
        (querySnapshot) => {
          this.messageThreads = querySnapshot.docs.map((d) =>
            Object.assign({ id: d.id }, d.data())
          );
          //   this.scrollToBottom();
          if (this.selectedThread.id) {
            this.listenToIncomingMessages(this.selectedThread.id);
          }
        },
        (error) => {}
      );
    },
    listenToIncomingMessages(id) {
      const unsubscribe = onSnapshot(
        doc(db, "users", this.admin.id, "messages", id),
        (querySnapshot) => {
          this.selectedThread = Object.assign(
            this.selectedThread,
            querySnapshot.data()
          );
          this.scrollToBottom();
        },
        (error) => {}
      );
    },
    async openNewChat(user) {
      debugger;
      this.searchQuery = [];
      this.searchValue = "";
      const admin = this.admin;
      const adminRef = doc(db, "users", admin.id, "messages", user.id);
      const userRef = doc(db, "users", user.id, "messages", admin.id);
      const contact = await getDoc(adminRef);
      if (!contact.exists()) {
        await setDoc(userRef, {
          name: `${admin.firstName} ${admin.lastName}`,
          messages: [],
        });

        setDoc(adminRef, {
          name: `${user.firstName} ${user.lastName}`,
          messages: [],
        })
          .then(() => {
            this.selectedThread = Object.assign(
              { id: user.id },
              {
                name: `${user.firstName} ${user.lastName}`,
                receiver: admin.id,
                sender: user.id,
                messages: [],
              }
            );
          })
          .catch((err) => {
            this.isLoading = false;
            this.displayError(err);
          });
      } else {
        this.selectedThread = Object.assign({ id: contact.id }, contact.data());
      }
    },
    async getAdminUsers() {
      this.users = this.$store.state.authModule.adminUsers;
    },
    async sendMessage(type) {
      const contact = this.selectedThread;
      const adminRef = doc(db, "users", this.admin.id, "messages", contact.id);
      const contactRef = doc(
        db,
        "users",
        contact.id,
        "messages",
        this.admin.id
      );

      if (contact.id) {
        if (type == "image") {
          const fileMetaData = await this.saveMediaToStorageAndRetrieveURL(
            this.uploadedFile
          );

          const message = {
            imageIncluded: true,
            image_meta_data: fileMetaData,
            text: this.captionValue,
            sender: this.admin.id,
            receiver: contact.id,
            sentAt: Date.now(),
          };

          this.updateMessageDocument([adminRef, contactRef], message);
        } else {
          if (this.newMessage.trim() !== "") {
            const message = {
              imageIncluded: false,
              text: this.newMessage,
              sender: this.admin.id,
              receiver: contact.id,
              sentAt: Date.now(),
            };
            this.updateMessageDocument([adminRef, contactRef], message);
          }
        }

        // this.selectedThread.messages.push(message);
        this.newMessage = "";
        // Simulate bot response (replace this with actual logic)
        this.inputBoxHeight = "auto";
        this.uploadedFile = null;
        this.uploadFileBlob = null;
        this.scrollToBottom();
      }
    },
    searchForUser(e) {
      this.searchValue = e.target.value;
      if (!this.searchValue) {
        this.searchQuery = [];
        return;
      }
      this.searchValue = this.searchValue.toLowerCase();
      this.searchQuery = this.users.filter(
        (u) =>
          u.firstName.toLowerCase().includes(this.searchValue) ||
          u.lastName.toLowerCase().includes(this.searchValue)
      );
    },
    updateMessageDocument(refs, message) {
      refs.forEach((ref) => {
        updateDoc(ref, {
          messages: arrayUnion(message),
        });
      });
    },
    adjustInputHeight() {
      // Set a minimum height to prevent the input box from collapsing
      const minHeight = "32px";

      // Calculate the scroll height of the textarea
      const scrollHeight = this.$refs.inputBox.scrollHeight;

      // Set the input box height based on the calculated scroll height
      this.inputBoxHeight = Math.max(minHeight, `${scrollHeight}px`);
    },
    scrollToBottom() {
      // Use a nextTick to ensure that the DOM updates have occurred
      this.$nextTick(() => {
        const messageList = this.$refs.messageList;
        messageList.scrollTop = messageList.scrollHeight;
      });
    },
    selectContact(contact) {
      this.selectedThread = contact;
      this.scrollToBottom();
    },
  },
};
</script>

<style scoped>
#image-container-msg {
  width: 300px;
}

#image-container-msg img {
  width: 100%;
}
.img-btn-close {
  position: absolute;
  z-index: 1091;
  right: 0;
  top: -10px;
}
.send-img-btn {
  position: absolute;
  bottom: -1px;
  right: -1px;
}
.uploaded-image {
  width: 100%;
  position: relative;
  max-height: 269px;
}
.caption-input {
  width: 76%;
  height: auto;
}
.image-container {
  height: 90%;
}
.image-modal {
  display: block;
  z-index: 1090;
  position: absolute;
  top: 30%;
  border: 1px solid black;
  background-color: lightcyan;
  height: 300px;
}
.hide_upload {
  position: absolute;
  z-index: -2;
  display: none;
}
input {
  width: 100%;
}
.selected {
  background-color: #e6e6e6;
}
.results {
  cursor: pointer;
  border-bottom: 0.5px black solid;
}

.results:hover {
  background-color: #dcf8c6;
}
.search-query {
  display: flex;
  max-height: 300px;
  border: 1px solid black;
  position: absolute;
  background-color: white;
  width: 186px;
  flex-direction: column;
}
.add-icon {
  color: green;
  margin-top: 7px;
  margin-left: 5px;
}
.add-contact {
  display: flex;
  bottom: 45px;
  position: absolute;
  cursor: pointer;
  border: 1px solid green;
  border-radius: 9px;
  height: 35px;
  width: 36px;
}
.modal-content {
  width: 1000px;
  height: 600px;
  left: -185px;
}
.icon-btn {
  position: fixed;
  z-index: 99;
  width: 3%;
  top: 94%;
  left: 95%;
  cursor: pointer;
}
.messenger-app {
  display: flex;
  height: 480px;
}

.contact-list {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #ccc;
  max-width: 250px;
  overflow-y: auto;
  background-color: #f7f7f7;
  max-height: 480px;
}

.contact {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.contact:hover {
  background-color: #e6e6e6;
}

.messenger {
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: #f1f0f0;
}

.message-list {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  height: 500px;
}

.message {
  color: black;
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
  max-width: 40%;
  word-wrap: break-word;
}

.outgoing {
  background-color: #dcf8c6;
  align-self: flex-end;
  max-width: 400px;
}

.incoming {
  background-color: #ffffff;
  align-self: flex-start;
  max-width: 400px;
}

.message-input {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f7f7f7;
  border-top: 1px solid #ccc;
  height: 100px;
}

textarea {
  resize: none;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px;
  overflow: auto;
  width: 550px;
  position: absolute;
  margin-left: 60px;
}

.send-btn {
  align-self: flex-end;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 43px;
}

.btn-close {
  align-self: flex-start;
  margin-top: 8px;
  padding: 5px 10px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.upload-icon {
  width: 20px;
  position: absolute;
  bottom: 30px;
  font-size: 20px;
  cursor: pointer;
}
</style>
