<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About User</h5>
    <p class="mb-0 text-sm">Mandatory Information</p>
    <div class="multisteps-form__content">
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <v-select
            :appendToBody="true"
            v-model="user.storeId"
            :options="stores"
            label="name"
            :reduce="(s) => s.id"
            placeholder="Designate Store"
            :closeOnSelect="true"
            :loading="!stores.length"
            :clearable="false"
          >
          </v-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.firstName"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Last Name</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Phone #</label>
          <div class="form-group">
            <div class="input-group">
              <!-- <input
                id="phone"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              /> -->
              <input
                type="tel"
                v-model="user.phone"
                @input="formatPhoneNumber"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                name="floating_phone"
                id="floating_phone"
                class="form-control multisteps-form__input"
                placeholder="eg: 347-123-1234"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Email Address</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.email"
                placeholder="eg: user@email.com"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Password</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.current"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Repeat Password</label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.repeat"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <!-- <soft-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto mb-0 js-btn-next"
            @click="this.$parent.nextStep"
            >Next</soft-button
          > -->
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="saveUser"
          >Send Invite</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

//Firebase
import {
  collection,
  doc,
  db,
  setDoc,
  getDocs,
  query,
  where,
  functions,
  httpsCallable,
} from "@/config/firebaseInit.js";

export default {
  name: "client-user-info",
  components: {
    SoftInput,
    SoftButton,
  },

  data() {
    return {
      addUser: httpsCallable(functions, "addUser"),
      selectedStore: "",
      inputPasswordType: "password",
      stores: [],
      errors: [],
      roles: {
        super: "DIS Executive",
        client: "Client Executive",
        store: "Store Employee",
        employee: "DIS Employee",
        factory: "Factory Employee",
      },
      password: {
        current: "",
        repeat: "",
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        client: "",
        storeId: "",
        role: "Store Rep",
        admin_access: {
          client: false,
          store: true,
        },
        createdAt: Date.now(),
      },
    };
  },
  computed: {
    formattedPhoneNumber() {
      const phoneNumberValue = this.user.phone.replace(/\D/g, "");
      const phoneNumberLength = phoneNumberValue.length;

      if (phoneNumberLength <= 3) {
        return phoneNumberValue;
      }

      if (phoneNumberLength <= 7) {
        return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(3)}`;
      }

      return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(
        3,
        6
      )}-${phoneNumberValue.slice(6, 10)}`;
    },
  },
  created() {
    this.fetchAllStores();
  },
  methods: {
    formatPhoneNumber() {
      this.user.phone = this.formattedPhoneNumber;
    },
    handleAdminAccess(e) {
      const access = e.target.value;
      this.user.admin_access = {
        super: false,
        client: false,
        store: false,
        employee: false,
        factory: false,
      };
      this.user.admin_access[access] = true;
      this.user.role = this.roles[access];
    },
    async saveUser() {
      this.user.client = Object.assign({
        id: this.$store.state.authModule.admin.id,
        name: this.$store.state.authModule.client.company,
        refNumber: this.$store.state.authModule.client.refNumber,
      });

      this.addUser({
        email: this.user.email,
        password: this.password.current,
        displayName: `${this.user.firstName} ${this.user.lastName}`,
      }).then(async (res) => {
        this.user.id = res.data;
        await setDoc(doc(db, "users", res.data), this.user).then(() => {
          this.$swal({
            icon: "success",
            title: "User Successfully Added",
            text: `User ${this.user.firstName} has been added to the roster`,
            type: "success-message",
          })
            .then(() =>
              this.sendConfirmationEmail(
                this.$store.state.authModule.client,
                this.user,
                this.password.current
              )
            )
            .then(() => this.$router.push("/users"));
        });
      });
    },
    async sendConfirmationEmail(client, user, password) {
      try {
        return await axios.post(
          "https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/send_email",
          {
            data: {
              contents: [
                {
                  template_id: 3,
                  csv: false,
                  subject: "Welcome To Luna Collection",
                  filename: null,
                  to: [user.email],
                  bcc: [
                    "kelvin@diamondservicesusa.com",
                    "sam@ttcusadiamond.com",
                    "tal@diamondservicesusa.com",
                  ],
                  cc: client.salesRep.email
                    ? [client.salesRep.email]
                    : ["rkonfino@yahoo.com"],
                  params: {
                    clientName: `${user.firstName} ${user.lastName}`,
                    clientPassword: password,
                    clientStoreID: user.refNumber,
                  },
                },
              ],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (err) {
        console.error(err);
        throw new Error(err);
      }
    },
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
    async fetchAllStores() {
      let storeQuery = collection(db, "stores");
      if (this.$store.state.authModule.isClient) {
        storeQuery = query(
          collection(db, "stores"),
          where("client.id", "==", this.$store.state.authModule.client.id)
        );
      }

      const stores = await getDocs(storeQuery);
      this.stores = stores.docs.map((user) =>
        Object.assign({ id: user.data().storeId, name: user.data().storeName })
      );
    },
  },
};
</script>
