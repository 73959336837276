<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About Sales Rep</h5>
    <p class="mb-0 text-sm">Mandatory Information</p>
    <div class="multisteps-form__content">
      <div class="col-12 col-sm-6 mt-3 mt-sm-0">
        <label>Company's Name (Optional)</label>
        <div class="form-group">
          <div class="input-group">
            <input
              id="company_name"
              type="text"
              class="form-control multisteps-form__input"
              name="text"
              v-model="salesRep.company"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Rep ID <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="repId"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="salesRep.repId"
                placeholder="Rep ID"
                required
              />
            </div>
          </div>
        </div>
        <div v-if="!$store.state.authModule.isStore" class="col-12 col-sm-6">
          <label>Designated Clients <span class="required">*</span></label>
          <div class="form-group">
            <v-select
              :appendToBody="true"
              v-model="salesRep.clients"
              :options="clients"
              label="company"
              multiple
              placeholder="Designate Store"
              :reduce="
                (c) =>
                  Object.assign({
                    id: c.id,
                    firstName: c.firstName,
                    lastName: c.lastName,
                    company: c.company,
                    collection: c.collection.id,
                    stores: c.stores,
                  })
              "
              :closeOnSelect="true"
              :loading="!clients.length"
              :clearable="false"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="salesRep.firstName"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Last Name <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="salesRep.lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Phone # <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="phone"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                v-model="salesRep.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Email Address <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="salesRep.email"
                placeholder="eg: salesRep@email.com"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Password <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.current"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Confirm Password <span class="required">*</span></label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.repeat"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i
                    v-if="inputPasswordType.includes('password')"
                    class="fas fa-eye"
                  ></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <!-- <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto mb-0 js-btn-next"
              @click="this.$parent.nextStep"
              >Next</soft-button
            > -->
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="saveUser"
          >Save</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

//Firebase
import {
  collection,
  doc,
  db,
  setDoc,
  getDocs,
  functions,
  httpsCallable,
  where,
  query,
} from "@/config/firebaseInit.js";

export default {
  name: "sales-rep-info",
  components: {
    SoftInput,
    SoftButton,
  },
  data() {
    return {
      addUser: httpsCallable(functions, "addUser"),
      selectedStore: "",
      inputPasswordType: "password",
      clients: [],
      errors: [],
      password: {
        current: "",
        repeat: "",
      },
      salesRep: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        clients: [],
        stores: [],
        repId: "",
        createdAt: Date.now(),
      },
    };
  },
  created() {
    this.fetchAllClients();
  },
  methods: {
    async fetchAllClients() {
      let storeQuery = collection(db, "clients");

      const clients = await getDocs(storeQuery);
      this.clients = clients.docs.map((client) =>
        Object.assign({ id: client.id }, client.data())
      );
    },
    getClientData() {
      return this.clients.filter((s) => s.id == this.salesRep.storeId)[0];
    },
    async saveUser() {
      let user = Object.assign(JSON.parse(JSON.stringify(this.salesRep)));
      this.salesRep.clients.forEach((c) =>
        c.stores.forEach((s) => {
          if (!this.salesRep.stores.includes(s)) {
            this.salesRep.stores.push(s);
          }
        })
      );
      delete user.repId;
      delete user.store;
      delete user.client;

      user.role = "Store Rep";
      user.admin_access = {
        client: false,
        store: false,
        salesRep: true,
      };

      this.addUser({
        email: this.salesRep.email,
        password: this.password.current,
        displayName: `${this.salesRep.firstName} ${this.salesRep.lastName}`,
      }).then(async (res) => {
        await setDoc(doc(db, "users", res.data), user);
        setDoc(doc(db, "sales_rep", res.data), this.salesRep).then(() => {
          this.$swal({
            icon: "success",
            text: "Sale's Rep Has Been Added Successfully and On Boarding Email was sent",
            title: `Sale's Rep ${this.salesRep.firstName} has been added to the roster`,
            type: "success-message",
          }).then(() => this.$router.push("/sales-reps"));
        });
      });
    },
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
  },
};
</script>
