<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto collapse navbar-collapse max-height-vh-100"
  >
    <ul class="navbar-nav">
      <!-- <li class="nav-item">
        <sidenav-collapse
          style="display: none"
          collapse-ref="dashboardsExamples"
          nav-text="Dashboards"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <Shop />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
             
              <sidenav-item
                :to="{ name: 'Default' }"
                mini-icon="D"
                text="Default"
              />

              <sidenav-item :to="{ name: 'CRM' }" mini-icon="C" text="CRM" />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="mt-6 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li>

      <!-- <li class="nav-item" style="display: none">
        <sidenav-collapse
          collapse-ref="applicationsExamples"
          nav-text="Applications"
          :class="getRoute() === 'applications' ? 'active' : ''"
        >
          <template #icon>
            <Settings />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              

              <sidenav-item
                :to="{ name: 'Kanban' }"
                mini-icon="K"
                text="Kanban"
              />
              <sidenav-item
                :to="{ name: 'Wizard' }"
                mini-icon="W"
                text="Wizard"
              />
              <sidenav-item
                :to="{ name: 'Data Tables' }"
                mini-icon="D"
                text="Data Tables"
              />
              <sidenav-item
                :to="{ name: 'Calendar' }"
                mini-icon="C"
                text="Calendar"
              />
              <sidenav-item
                :to="{ name: 'Analytics' }"
                mini-icon="A"
                text="Analytics"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->

      <!-- <li class="nav-item" v-if="currentUser && currentUser.admin_access.super">
        <sidenav-collapse
          collapse-ref="Vault"
          nav-text="Vault"
          :class="getRoute() === 'inventory' ? 'active' : ''"
        >
          <template #icon>
            <Shop />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              nav links

              <sidenav-item
                :to="{ name: 'Products List' }"
                mini-icon="I"
                text="Inventory"
              />

              <sidenav-item
                :to="{ name: 'Product Orders' }"
                mini-icon="PO"
                text="Product Orders"
              />

              <sidenav-item
                :to="{ name: 'Shipping' }"
                mini-icon="S"
                text="Shipping"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li
        class="nav-item"
        v-if="currentUser && !currentUser.admin_access.factory"
      >
        <sidenav-collapse
          collapse-ref="sales"
          nav-text="Sales"
          :class="
            getRoute() === 'stores' ||
            getRoute() === 'Orders' ||
            getRoute() === 'clients' ||
            getRoute() === 'sales-reps' ||
            getRoute() === 'factory' ||
            getRoute() === 'collections'
              ? 'active'
              : ''
          "
        >
          <template #icon>
            <Shop />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Order List' }"
                mini-icon="U"
                text="Orders"
              />
              <sidenav-item
                v-if="
                  currentUser &&
                  (currentUser.admin_access.super ||
                    currentUser.admin_access.employee)
                "
                :to="{ name: 'Collections' }"
                mini-icon="C"
                text="Collections"
              />
              <sidenav-item
                v-if="
                  currentUser &&
                  (currentUser.admin_access.super ||
                    currentUser.admin_access.employee)
                "
                :to="{ name: 'Sales Rep' }"
                mini-icon="S"
                text="Sales Rep"
              />
              <sidenav-item
                v-if="
                  currentUser &&
                  (currentUser.admin_access.super ||
                    currentUser.admin_access.employee ||
                    currentUser.admin_access.salesRep)
                "
                :to="{ name: 'Clients' }"
                mini-icon="C"
                text="Clients"
              />
              <sidenav-item
                v-if="
                  currentUser &&
                  (currentUser.admin_access.super ||
                    currentUser.admin_access.employee ||
                    currentUser.admin_access.client)
                "
                :to="{ name: 'Store List' }"
                mini-icon="U"
                text="Stores"
              />
              <sidenav-item
                v-if="
                  currentUser &&
                  !currentUser.admin_access.super &&
                  !currentUser.admin_access.employee
                "
                :to="{ name: 'Shipping' }"
                mini-icon="S"
                text="Shipping"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        v-if="
          currentUser &&
          (currentUser.admin_access.factory ||
            currentUser.admin_access.super ||
            currentUser.admin_access.employee)
        "
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="Factory"
          nav-text="Factory"
          :class="getRoute() === 'factory' ? 'active' : ''"
        >
          <template #icon>
            <Warehouse />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Factory Order List' }"
                mini-icon="O"
                text="Orders"
              />

              <sidenav-item
                :to="{ name: 'Shipping' }"
                mini-icon="S"
                text="Shipping"
              />
            </ul>
          </template>
        </sidenav-collapse>
        <!-- <div
          aria-expanded="false"
          class="nav-link"
          :class="getRoute() === 'shipping' ? 'active' : ''"
        >
          <div
            class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center me-2"
          >
            <Shipping />
          </div>
          <router-link class="ms-1" :to="{ name: 'Shipping List' }">
            <span class="sidenav-normal"> Factory </span>
          </router-link>
        </div> -->
      </li>

      <li
        v-if="
          currentUser &&
          (currentUser.admin_access.super || currentUser.admin_access.employee || currentUser.admin_access.client)
        "
        class="nav-item"
      >
        <sidenav-collapse
          collapse-ref="admin"
          nav-text="Administration"
          :class="getRoute() === 'users' ? 'active' : ''"
        >
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <!-- <sidenav-collapse-item
                refer="profileExample"
                mini-icon="P"
                text="Profile"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Profile Overview' }"
                    mini-icon="P"
                    text="Profile Overview"
                  />
                  <sidenav-item
                    :to="{ name: 'Teams' }"
                    mini-icon="T"
                    text="Teams"
                  />
                  <sidenav-item
                    :to="{ name: 'All Projects' }"
                    mini-icon="A"
                    text="All Projects"
                  />
                </template>
              </sidenav-collapse-item> -->

              <sidenav-item
                :to="{ name: 'Users' }"
                mini-icon="U"
                text="Users"
              />

              <!-- <sidenav-collapse-item
                refer="accountExample"
                mini-icon="A"
                style="display: none"
                text="Account"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Settings' }"
                    mini-icon="S"
                    text="Settings"
                  />
                  <sidenav-item
                    :to="{ name: 'Billing' }"
                    mini-icon="B"
                    text="Billing"
                  />
                  <sidenav-item
                    :to="{ name: 'Invoice' }"
                    mini-icon="I"
                    text="Invoice"
                  />
                  <sidenav-item
                    :to="{ name: 'Security' }"
                    mini-icon="S"
                    text="Security"
                  />
                </template>
              </sidenav-collapse-item> -->

              <!-- <sidenav-collapse-item
                style="display: none"
                refer="projectsExample"
                mini-icon="P"
                text="Projects"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'General' }"
                    mini-icon="G"
                    text="General"
                  />
                  <sidenav-item
                    :to="{ name: 'Timeline' }"
                    mini-icon="T"
                    text="Timeline"
                  />
                  <sidenav-item
                    :to="{ name: 'New Project' }"
                    mini-icon="N"
                    text="New Project"
                  />
                </template>
              </sidenav-collapse-item> -->

              <!-- <sidenav-item
                style="display: none"
                :to="{ name: 'Widgets' }"
                mini-icon="W"
                text="Widgets"
              />
              <sidenav-item
                style="display: none"
                :to="{ name: 'Charts' }"
                mini-icon="C"
                text="Charts"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Settings from "../../components/Icon/Settings.vue";
import Basket from "../../components/Icon/Basket.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Document from "../../components/Icon/Document.vue";
import Shipping from "../../components/Icon/Shipping.vue";
import Warehouse from "../../components/Icon/Warehouse.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    Settings,
    Basket,
    Warehouse,
    Shop,
    Office,
    Document,
    Shipping,
    CustomerSupport,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
    }),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
