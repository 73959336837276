<template>
  <div class="form-group">
    <div class="input-group">
      <input
        id="autocomplete"
        ref="autocomplete"
        type="text"
        class="form-control multisteps-form__input"
        name="text"
        :disabled="disabled"
        :value="addressOne"
        placeholder="eg. Street 111"
        :required="required"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "autocomplete-input",
  props: {
    addressOne: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selected"],
  data() {
    return {
      address: {
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        zipCode: "",
      },
      predictions: [],
    };
  },
  mounted() {
    this.initAutocomplete();
  },
  methods: {
    initAutocomplete() {
      // Initialize the autocomplete
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        {
          types: ["geocode"],
        }
      );

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      this.autocomplete.addListener("place_changed", () => {
        let address = this.autocomplete.getPlace();

        if (!address.geometry || !address.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        address = address.address_components;
        const payload = {
          addressOne: `${address[0].long_name} ${address[1].long_name}`,
          addressTwo: null,
          city: address[3].long_name,
          state: address[5].long_name,
          zipCode: address[7] ? address[7].long_name : "",
        };

        this.$emit("selected", payload);
      });

      // Listen for the event fired when the user types in the search box and fetch predictions
      this.autocomplete.addListener("keyup", () => {
        if (this.autocomplete.getPlace()) {
          return;
        }

        if (this.$refs.autocomplete.value.length < 3) {
          this.predictions = [];
          return;
        }

        const service = new google.maps.places.AutocompleteService();

        service.getPlacePredictions(
          { input: this.$refs.autocomplete.value },
          (predictions) => {
            this.predictions = predictions;
          }
        );
      });
    },
  },
};
</script>

<style>
</style>